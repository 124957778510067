import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

import React, { useEffect, Fragment } from "react"
import { Link, graphql } from "gatsby"
import { getUrlParameterByKey, setUrlParameter } from "../components/helpers"

import RadarChart from "react-svg-radar-chart"
import "react-svg-radar-chart/build/css/index.css"

import Layout from "../components/layout"
import Breadcrumb from "../components/breadcrumb"

const ResultPage = ({ data, pageContext }) => {
  const { slug } = pageContext
  const {
    title,
    summary,
    topCompetencies,
    businessFunction,
    file,
  } = data.contentfulRole

  const fileURL = file !== null ? file.file.url : ""
  const sortBy = (array, sortBy) => array.sort((a, b) => a[sortBy] - b[sortBy])
  const makeString = arr => {
    if (arr.length === 1) return arr[0]
    const firsts = arr.slice(0, arr.length - 1)
    const last = arr[arr.length - 1]
    return firsts.join(", ") + " and " + last
  }

  const allCompetencies = topCompetencies.sort(function(a, b) {
    if (a.competency.title < b.competency.title) {
      return -1
    }
    if (a.competency.title > b.competency.title) {
      return 1
    }
    return 0
  })

  const competenciesToDevelop = allCompetencies.filter(item => {
    return (
      typeof window !== "undefined" &&
      parseInt(getUrlParameterByKey(item.competency.slug), 10) <
        item.proficiency.value
    )
  })

  const competenciesToDevelopString = competenciesToDevelop
  competenciesToDevelopString

  const competenciesToDisplay = allCompetencies.filter(item => {
    return !competenciesToDevelop.includes(item)
  })

  var role = {}
  for (var i = 0; i < allCompetencies.length; i++) {
    role[i + 1] = allCompetencies[i].proficiency.value / 4
  }

  var you = {}
  if (typeof window !== "undefined") {
    for (var i = 0; i < allCompetencies.length; i++) {
      you[i + 1] =
        parseInt(getUrlParameterByKey(allCompetencies[i].competency.slug), 10) /
        4
    }
  }

  const gdata = [
    {
      data: role,
      meta: { color: "rgba(245, 77, 74, 1)" },
    },
    {
      data: you,
      meta: { color: "rgba(68, 140, 219, 1)" },
    },
  ]

  var captions = {}
  for (var i = 0; i < topCompetencies.length; i++) {
    captions[i + 1] = topCompetencies[i].competency.title
  }

  const defaultOptions = {
    size: 300,
    axes: true, // show axes?
    scales: 4, // show scale circles?
    captions: true, // show captions?
    captionMargin: 0,
    dots: true, // show dots?
    zoomDistance: 1.54, // where on the axes are the captions?
    setViewBox: options =>
      `-${options.captionMargin} 0 ${options.size +
        options.captionMargin * 2} ${options.size}`, // custom viewBox ?
    axisProps: () => ({ className: "axis" }),
    scaleProps: () => ({ className: "scale", fill: "none" }),
    shapeProps: () => ({ className: "shape" }),
    captionProps: () => ({
      className: "caption",
      textAnchor: "middle",
      fontSize: 12,
      fontFamily: "Source Sans Pro",
    }),
    // dotProps: () => ({
    //   className: "dot",
    //   mouseEnter: dot => {
    //     console.log(dot)
    //   },
    //   mouseLeave: dot => {
    //     console.log(dot)
    //   },
    // }),
  }

  const shareUrl =
    typeof window !== "undefined" ? window.location.href + "&share=true" : ""
  return (
    <Layout>
      <Breadcrumb current="3" pageContext={pageContext} />
      <div className="role result">
        <div className="role-column role-column__one">
          <div className="container">
            <p className="role__item-subtitle">{businessFunction.title}</p>
            <h1 className="role__item-title">{title}</h1>
            <div className="role__item-result">
              <p className="role__item-result-label">Competency Results</p>
              <div className="role__item-result-icons">
                <p className="blue">You</p>
                <p className="brand">Role</p>
              </div>
            </div>
            <div className="spider-chart">
              {Object.keys(you).length > 0 && (
                <RadarChart
                  captions={captions}
                  data={gdata}
                  size={300}
                  options={defaultOptions}
                />
              )}
            </div>
            <p className="role__item-result-todevelop">
              Competencies to develop:
            </p>
            <p className="role__item-result-todevelop-small">
              {competenciesToDevelop.map((item, index) => {
                let comma = index < competenciesToDevelop.length - 1 ? ", " : ""
                return (
                  <Fragment key={item.competency.slug}>
                    {item.competency.title}
                    {comma}
                  </Fragment>
                )
              })}
            </p>
          </div>
        </div>
        <div className="role-column role-column__two">
          <div className="tabset">
            <input
              type="radio"
              name="tabset"
              id="tab1"
              aria-controls="about"
              defaultChecked={
                typeof window !== "undefined" &&
                getUrlParameterByKey("share") !== "true"
                  ? true
                  : false
              }
            />
            <label htmlFor="tab1">About the Role</label>
            <input
              type="radio"
              name="tabset"
              id="tab2"
              aria-controls="competencies"
              defaultChecked={
                typeof window !== "undefined" &&
                getUrlParameterByKey("share") === "true"
                  ? true
                  : false
              }
            />
            <label htmlFor="tab2">
              Skill Gap Analysis
              {competenciesToDevelop.length > 0 && (
                <a className="notification-icon--fixed">
                  <small className="notification-badge">
                    {competenciesToDevelop.length}
                  </small>
                </a>
              )}
            </label>

            <div className="tab-panels">
              <div className="container">
                <section id="about" className="tab-panel">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(JSON.parse(summary.summary)),
                    }}
                  ></div>
                  {fileURL.length > 0 && (
                    <a
                      className="role__item-link"
                      href={fileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Download Role Description</span>
                    </a>
                  )}
                </section>
                <section id="competencies" className="tab-panel">
                  <p className="start-heading">
                    Top {topCompetencies.length} competencies for this role
                  </p>
                  <div className="competencies-develop-wrapper">
                    {competenciesToDevelop.map(item => (
                      <div
                        key={item.competency.slug}
                        className="competency-result"
                      >
                        <p className="competency-result-title">
                          {item.competency.title}{" "}
                          <span className="competency-result-develop">
                            to develop
                          </span>
                        </p>
                        <p className="competency-result-description">
                          {item.competency.description.description}
                        </p>
                      </div>
                    ))}
                  </div>
                  {competenciesToDisplay.map(item => (
                    <div
                      key={item.competency.slug}
                      className="competency-result"
                    >
                      <p className="competency-result-title">
                        {item.competency.title}
                      </p>
                      <p className="competency-result-description">
                        {item.competency.description.description}
                      </p>
                    </div>
                  ))}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="buttons-wrapper">
        <Link to="/" className="previous-button small">
          <span className="label">
            Choose <span className="desktop-only">another</span> role
          </span>
        </Link>

        {shareUrl.length > 0 && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="next-button small"
            href={`mailto:?subject=Nissan%20Desired%20Role%20-%20${encodeURIComponent(
              title
            )}&body=I%E2%80%99ve%20completed%20the%20Nissan%20Desired%20Role%20self%20assessment.%0D%0ATo%20view%20the%20results%20please%20click%20the%20link%20below%3A%0D%0A${encodeURIComponent(
              shareUrl
            )}`}
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.4999 0.875026H2.4999C2.1706 0.871427 1.84389 0.933634 1.53895 1.058C1.23401 1.18236 0.956984 1.36637 0.724117 1.59924C0.491251 1.83211 0.307237 2.10914 0.182875 2.41408C0.0585122 2.71902 -0.00369485 3.04572 -9.64925e-05 3.37503V14.625C-0.00369485 14.9543 0.0585122 15.281 0.182875 15.586C0.307237 15.8909 0.491251 16.1679 0.724117 16.4008C0.956984 16.6337 1.23401 16.8177 1.53895 16.9421C1.84389 17.0664 2.1706 17.1286 2.4999 17.125H17.4999C17.8292 17.1286 18.1559 17.0664 18.4609 16.9421C18.7658 16.8177 19.0428 16.6337 19.2757 16.4008C19.5086 16.1679 19.6926 15.8909 19.8169 15.586C19.9413 15.281 20.0035 14.9543 19.9999 14.625V3.37503C20.0035 3.04572 19.9413 2.71902 19.8169 2.41408C19.6926 2.10914 19.5086 1.83211 19.2757 1.59924C19.0428 1.36637 18.7658 1.18236 18.4609 1.058C18.1559 0.933634 17.8292 0.871427 17.4999 0.875026ZM17.8749 5.12503L10.3749 10.75C10.2667 10.8312 10.1351 10.875 9.9999 10.875C9.86467 10.875 9.73309 10.8312 9.6249 10.75L2.1249 5.12503C1.9923 5.02557 1.90463 4.87751 1.88119 4.71341C1.85774 4.54932 1.90045 4.38263 1.9999 4.25003C2.09936 4.11742 2.24742 4.02975 2.41152 4.00631C2.57561 3.98287 2.7423 4.02557 2.8749 4.12503L9.9999 9.46878L17.1249 4.12503C17.1906 4.07578 17.2653 4.03995 17.3448 4.01958C17.4243 3.99921 17.507 3.9947 17.5883 4.00631C17.6695 4.01791 17.7477 4.04541 17.8183 4.08723C17.889 4.12905 17.9507 4.18436 17.9999 4.25003C18.0491 4.31569 18.085 4.3904 18.1054 4.46991C18.1257 4.54942 18.1302 4.63216 18.1186 4.71341C18.107 4.79467 18.0795 4.87283 18.0377 4.94346C17.9959 5.01408 17.9406 5.07578 17.8749 5.12503Z" />
            </svg>
            <span className="label">
              Share<span className="desktop-only">&nbsp;your Results</span>
            </span>
          </a>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    contentfulRole(id: { eq: $id }) {
      id
      title
      slug
      businessFunction {
        title
        slug
      }
      file {
        file {
          url
        }
      }
      summary {
        summary
      }
      topCompetencies {
        proficiency {
          title
          slug
          value
        }
        competency {
          title
          slug
          description {
            description
          }
        }
      }
    }
  }
`

export default ResultPage
