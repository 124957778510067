export const removeUrlParameters = array => {
  let urlQueryParameters = window.location.search

  if (!array.length) {
    return urlQueryParameters
  }

  const removeUrlParameters = array.map(key => {
    if (urlQueryParameters.includes(key)) {
      const regExp = new RegExp(`&${key}=([a-z0-9-!%20;]+)`, "i")
      urlQueryParameters = urlQueryParameters.replace(regExp, ``)
    }

    return urlQueryParameters
  })
  return removeUrlParameters.pop()
}

export const getUrlParameterByKey = key => {
  const urlQueryParameters = window.location.search
  const regExp = !key
    ? new RegExp("[a-z0-9-!=+]+", "ig")
    : new RegExp(`${key}=([a-z0-9-!+%20+@.;]+)`, "i")

  if (!key) {
    const objectOfParameters = urlQueryParameters
      .match(regExp)
      .reduce((acc, cur) => {
        const [key, value] = cur.split("=")
        return {
          ...acc,
          [key]: value,
        }
      }, {})
    return objectOfParameters
  }
  if (urlQueryParameters.includes(key) && urlQueryParameters.match(regExp)) {
    return urlQueryParameters
      .match(regExp)[1]
      .replace(new RegExp("%20", "g"), " ")
  }

  return null
}

export const setUrlParameter = (key, val, remove) => {
  const urlQueryParameters = remove ? remove : window.location.search
  if (!key || !val) {
    return urlQueryParameters
  }
  if (urlQueryParameters.includes(key)) {
    const regExp = new RegExp(`${key}=([a-z0-9-!%20;]+)|${key}=|${key}`, "i")
    return urlQueryParameters.replace(regExp, `${key}=${val}`)
  }

  return urlQueryParameters
    ? `${urlQueryParameters}&${key}=${val}`
    : urlQueryParameters
}
